export const defaultThemeDimensions = { colorScheme: 'light', density: 'spacious', animation: 'lively' };
export const fileFormats = { css: 'css', cssVar: 'css-var', scss: 'scss', js: 'js', json: 'json', ios: 'ios', android: 'android' };
export const prodBaseUrl = 'https://design-tokens.cdn.allianz.com/';
export const themeDimensions = { colorScheme: 'colorScheme', density: 'density', animation: 'animation' };
export const tokenTypes = {
	borderRadius: 'borderRadius',
	borderWidth: 'borderWidth',
	color: 'color',
	dimension: 'dimension',
	easing: 'easing',
	fontFamilies: 'fontFamilies',
	fontSizes: 'fontSizes',
	fontWeights: 'fontWeights',
	inset: 'inset',
	letterSpacing: 'letterSpacing',
	lineHeights: 'lineHeights',
	other: 'other',
	scaling: 'scaling',
	sizing: 'sizing',
	spacing: 'spacing',
	textDecoration: 'textDecoration',
	time: 'time',
};
export const typoPreviewText = 'Lorem Ipsum';
